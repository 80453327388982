<template>
  <b-modal size="lg" v-model="active" ok-only no-stacking dialog-class="modal-dialog-centered" title="Coupon Detail">
    <div class="row">
      <div class="col table-responsive">
        <table class="table table-bordered table-hover table-sm custom-table">
          <tbody v-if="coupon">
          <tr style="color: #d14343">
            <th>Percent</th>
            <td>{{ (coupon.percent * 100).toFixed(0) }}%</td>
          </tr>
          <tr style="color: #d14343">
            <th>Code</th>
            <td>{{ coupon.code }}</td>
          </tr>
          <tr>
            <th>Limit</th>
            <td>{{ coupon.limit ? coupon.limit : '&infin;' }}</td>
          </tr>
          <tr>
            <th>Redeemed</th>
            <td>{{ coupon.used }}</td>
          </tr>
          <tr>
            <th>Start date</th>
            <td>{{ coupon.date_start|localDateShort }} <br> Time zone: UTC</td>
          </tr>
          <tr>
            <th>End date</th>
            <td>
              {{ coupon.date_end|localDateShort}} <br> Time zone: UTC
            </td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ coupon.description }}</td>
          </tr>
          <tr>
            <th>On modified TVS</th>
            <td> {{ coupon.on_modified_price.tvs_retail ? 'Yes' : 'No' }}</td>
          </tr>
          <tr>
            <th>On modified Local</th>
            <td> {{ coupon.on_modified_price.tvs_local ? 'Yes' : ' No' }}</td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered" v-if="coupon">
          <tr>
            <th>Percent /</th>
            <th>Qt. / redeemed</th>
            <th>Type (s)</th>
            <th>Type restrictions.</th>
          </tr>
          <tr v-for="(subCoupon, index) of coupon.sub_coupons" :key="index">
            <td>{{ subCoupon.percent * 100 }} %</td>
            <td>{{ subCoupon.limit ? subCoupon.limit : '&infin;' }} / {{ subCoupon.used }}</td>
            <td style="white-space: break-spaces">{{ subCoupon.types.join('\n') }}</td>
            <td>
              <template v-for="sub of subCoupon.types">
                <p>{{ sub }}: {{ (subCoupon[sub] ? toShow(subCoupon, sub) : 'All') | orderTypeExtended }}</p>
              </template>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="$userCan(['administrator','support'])" class="col table-responsive"
           style="max-height: 30vw; overflow-y: scroll">
        <div style="max-height: 14rem; overflow-y: scroll">
          <table class="table table-bordered table-hover table-sm">
            <thead>
            <tr>
              <th>Users</th>
            </tr>
            </thead>
            <tbody v-if="coupon">
            <tr v-for="(item, index) in coupon.users" :key="index">
              <td>{{ item.username }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div style="max-height: 14rem; overflow-y: scroll">
        <table class="table table-bordered table-hover table-sm">
          <thead>
          <tr>
            <th>Software orders </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in orders" :key="index">
            <td>{{item.order_id}}</td>
            <td @click="redirect(item.id)" style="cursor:pointer;">
              <i class="material-icons">visibility</i>
            </td>
          </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {get} from "@/services/api";
import {encrypt} from "@/helpers/encrypt";

export default {
  name: "DetailCoupon",
  data: () => ({
    coupon: null,
    active: false,
    orders: []
  }),
  methods: {
    open(coupon) {
      this.coupon = coupon;
      get(`coupon/${coupon._id}`).then(res => {
        this.coupon.users = res.data.users;
        this.orders = res.data.orders
      });
      this.active = true;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.form.validate().then(success => {
        if (success) {

        }
      });
    },
    toShow(subCoupon, key) {
      if (Array.isArray(subCoupon[key])) {
        return subCoupon[key].join(',')
      } else {
        return Object.values(subCoupon[key]).filter(Boolean).join(',')
      }
    },
    async redirect(id) {
      await this.$router.push({name: 'order-detail', params: {id: encrypt(JSON.stringify({id: id}))}});
    }
  },
}
</script>

<style scoped>
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #c93131; /* Blue */
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.div_collapse {
  position: absolute;
  background: white;
  z-index: 1000;
  display: none
}

.div_ul {
  max-height: 10vw;
  overflow-y: scroll;
}

.ul_dealers {
  list-style: none;
  margin-left: 0;
  padding-left: 0.5vw;
  padding-right: 0.5vw
}
</style>
