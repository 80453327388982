<template>
  <b-modal size="lg" ref="modal" dialog-class="modal-dialog-centered">
    <template #modal-header="{ close }">
      <h5>Create new Coupon</h5>
      <b-button class="btn-outline-primary" @click="showHelp"><span class="material-icons">help_outline</span>
      </b-button>
    </template>
    <template #modal-footer="">
      <b-button v-if="!showDivHelp" class="btn-danger" @click="handleOk">SAVE</b-button>
      <b-button v-if="showDivHelp" class="btn-danger" @click="showHelp">GO BACK</b-button>
    </template>
    <validation-observer v-if="!showDivHelp" v-slot="{handleSubmit}" ref="form">
      <form @submit.prevent="handleSubmit(save)">
        <b-row>
          <div class="form-group col-lg-3">
            <b-row>
              <b-col><h5 style="color: #d14343">Mandatory</h5></b-col>
            </b-row>
            <validation-provider rules="required|between:0,100" v-slot="{errors}" name="discount">
              <div class="form-group mt-2">
                <label>Discount percentage</label>
                <input type="number" v-model="percentage" class="form-control">
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider rules="required|min:3|max:10" v-slot="{errors}" name="Code">
              <div class="form-group mt-2">
                <div class="mb-1" style="display:flex; justify-content: space-between">
                  <label>Code</label>
                  <b-button @click="generateCode()" class="btn-outline-primary">Generate random code</b-button>
                </div>
                <input type="text" v-model="code" class="form-control">
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </validation-provider>

          </div>
          <div class="form-group col-lg-9 table-responsive" style="max-height: 10vw; overflow-y: scroll">
            <div class="my-2">
              <label>Use default.</label>
              <select class="form-control"
                      @change="$refs.subCoupons.addFromDefault(defaults[$event.target.value])">
                <option value="">Select</option>
                <option v-for="(option, index) of defaults" :value="index">{{ option.name }}</option>
              </select>
            </div>
            <table class="table table-bordered table-hover table-sm">
              <thead>
              <tr>
                <th>NAMES</th>
                <th>COUNTRY</th>
                <th>SHARE WITH NETWORK</th>
                <th>OPTIONS</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in userList" :key="index">
                <td>{{ item.username }}</td>
                <td>{{ item.country }}</td>
                <td v-if="item.share"><span style="color: #c93131">SHARED</span></td>
                <td v-if="!item.share">DON'T SHARED</td>
                <td style="display: flex; justify-content: space-around">
                  <b-button style="padding: 0.1vw 0.3vw " variant="danger" size="sm" @click="deleteUser(index)">
                    <span style="font-size: 1vw" class="material-icons">delete</span>
                  </b-button>
                  <b-button v-if="item.network.children !==null" style="padding: 0.1vw 0.3vw" variant="primary"
                            size="sm" @click="shareWithUser(item, index)">
                    <span style="font-size: 1vw" class="material-icons">share</span>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-row>
        <hr>
        <b-row>
          <b-col><h5 style="color: #d14343">Optional</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>Filter Dealers</label>
            <input type="text" class="form-control" v-model="filter">
            <div ref="btn_collapse" id="collapse-carDetail" class="div_collapse">
              <div class="list-group div_ul">
                <ul class="ul_dealers">
                  <li class="list-group-item list-group-item-action" style="white-space: nowrap;"
                      v-for="(item, index) in filterList" :key="index" @click="addUser(item ,index)">{{ item.username }}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>Quantity</label>
              <input type="text" v-model="quantity" class="form-control">
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col><h5 style="color: #d14343">Date</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <label>Date Start. Time zone: UTC</label>
              <datepicker placeholder="Select date" input-class="input_datepicker" v-model="dateStart"></datepicker>
            </div>
          </b-col>
          <b-col>
            <div><label>Date End. Time zone: UTC</label>
              <datepicker placeholder="Select date" input-class="input_datepicker" v-model="dateEnd"></datepicker>
            </div>
          </b-col>
        </b-row>
        <hr>
        <create-sub-coupon :coupon_quantity="quantity" ref="subCoupons"/>
        <hr>
        <b-row>
          <b-col><h5 class="text-danger">Apply discount on modified local prices.</h5></b-col>
        </b-row>
        <b-row no-gutters class="border pt-2 px-3">
          <b-col lg="3">
            <div class="form-group">
              <b-checkbox v-model="onModifiedPriceApply.tvs_retail" label="TVS Retail">TVS Retail</b-checkbox>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="form-group">
              <b-checkbox v-model="onModifiedPriceApply.tvs_local" label="TVS Local">TVS Local (modified)</b-checkbox>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col>
            <label>Description</label>
            <textarea type="text" class="form-control" v-model="description"></textarea>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
    <b-row class="mt-4" v-if="showDivHelp">
      <b-col>
        <ul style="list-style: none">
          <li><span style="font-weight: bold">Discount percentage</span>: Percentage that will be discounted from the
            price of the software order
          </li>
          <li><span style="font-weight: bold">Code</span>: The user uses the code to obtain the discount</li>
          <li><span style="font-weight: bold">Filter Dealers</span>: Only selected dealers can use the coupon</li>
          <li><span style="font-weight: bold">Quantity</span>: Number of times the coupon can be used</li>
          <li><span style="font-weight: bold">Stage</span>: Only applies to the selected stage</li>
        </ul>
      </b-col>
      <b-col>
        <ul style="list-style: none">
          <li><span style="font-weight: bold">Date Start</span>: Coupon start date. Time zone: UTC</li>
          <li><span style="font-weight: bold">Date End</span> : Coupon end date. Time zone: UTC</li>
          <li><span style="font-weight: bold">Type</span> : Type tuning</li>
          <li><span style="font-weight: bold">DSG Family</span> : The coupon only applies to the selected DSG</li>
          <li><span style="font-weight: bold">Description</span> : Explanation or comments for the dealers</li>
        </ul>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {post} from "@/services/api";
import {couponCreate} from "@/services/endpoint.json";
import Datepicker from 'vuejs-datepicker';
import {generateOnlyRandomString} from "@/utils";
import CreateSubCoupon from "@/views/dashboard/coupon/CreateSubCoupon";
import show_alert_mixin from "@/utils/show_alert_mixin";


export default {
  name: "CreateCoupon",
  mixins: [show_alert_mixin],
  components: {
    CreateSubCoupon,
    Datepicker,
  },
  props: {
    users: {
      default: []
    }
  },
  data: () => ({
    dealerSelected: '',
    dateStart: null,
    dateEnd: null,
    userList: [],
    filter: '',
    percentage: 0,
    quantity: null,
    description: '',
    code: null,
    showDivHelp: false,
    defaults: [
      {
        name: 'TVS replacement Hardware',
        value: [
          {
            limit: null,
            percent: 1,
            types: ["DSG"],
            dsg_family: [],
            vehicle: {brand: null, model: null, engine: null, generation: null},
            DSG: ["original_file"],
            used: 0
          },
          {
            limit: null,
            percent: 0.5,
            types: ["DSG"],
            dsg_family: [],
            vehicle: {brand: null, model: null, engine: null, generation: null},
            DSG: ["stock"],
            used: 0
          },
          {
            limit: null,
            percent: 0.25,
            types: ["DSG"],
            dsg_family: [],
            vehicle: {brand: null, model: null, engine: null, generation: null},
            DSG: ["1", "2", "2.1", "3", "4"],
            used: 0
          }
        ]
      },
      {
        name: 'TVS race clutches',
        value: [
          {
            limit: null,
            percent: 1,
            types: ["upgrade"],
            dsg_family: [],
            vehicle: {brand: null, model: null, engine: null, generation: null},
            used: 0
          },
          {
            limit: null,
            percent: 0.25,
            types: ["DSG"],
            dsg: [],
            vehicle: {brand: null, model: null, engine: null, generation: null},
            DSG: ["4"],
            used: 0
          }
        ]
      }
    ],
    types: [
      {text: 'ECU', value: 'ECU'},
      {text: 'COMBO', value: 'ECU + DSG'},
      {text: 'DSG', value: 'DSG'},
    ],
    combos: [
      {text: '1 - 1', value: '1-1'},
      {text: '2 - 2', value: '2-2'},
      {text: '2 - (2+)', value: '2-2.1'},
      {text: '(2+) - (2+)', value: '2.1-2.1'},
      {text: '2 - 3', value: '2-3'},
      {text: '(2+) - 3', value: '2.1-3'},
      {text: '3 - 3', value: '3-3'},
      {text: '3 - 4', value: '3-4'},
      {text: '4 - 3', value: '4-3'},
      {text: '4 - 4', value: '4-4'},
    ],
    ecuDsg: [
      {text: 'Orig File', value: 'original_file'},
      {text: 'Drivability', value: 'stock'},
      {text: '1', value: '1'},
      {text: '2', value: '2'},
      {text: '2+', value: '2.1'},
      {text: '3', value: '3'},
      {text: '4', value: '4'}
    ],
    typesSelected: {},
    onModifiedPriceApply: {
      tvs_retail: false,
      tvs_local: false,
    }
  }),
  computed: {
    filterList: function () {
      if (this.filter) {
        return this.users.filter(el => {
          return el.username.toUpperCase().includes(this.filter.toUpperCase()) && !this.userList.find(us => us.id === el.id);
        });
      }
      return [];
    }
  },
  methods: {
    open() {
      this.$refs.modal.show()
    },
    save() {
      if (this.percentage === 0 && !this.$refs.subCoupons.subCoupons.length) {
        this.showAlertNotification('Please enter a discount percentage or create a sub coupon, A coupon with 0% discount only apply if it has sub-coupons.', 'error')
        return;
      }
      if (!this.dateStart && this.dateEnd || this.dateStart && !this.dateEnd) {
        this.showAlertNotification('Please enter both start and end date', 'error')
        return;
      }

      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          this.$refs.modal.hide();
          post(couponCreate, {
            percent: (Number(this.percentage) / 100),
            users: this.userList.map(el => ({id: el.id, share: el.share})),
            date_start: this.dateStart,
            date_end: this.dateEnd,
            limit: this.quantity,
            description: this.description,
            code: this.code.toUpperCase(),
            on_modified_price: this.onModifiedPriceApply,
            sub_coupons: this.$refs.subCoupons.subCoupons,
          }, true).then(({data}) => {
            if (data) {
              this.clearForm()
              this.showAlertNotification('Coupon created successfully')
            } else this.showAlertNotification('Code already exist', 'error')
            this.$emit('refresh');
          }).catch(err => {
            this.showAlertNotification(err.response.data.message, 'error')
          })
        }
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.$refs.form !== undefined)
        this.$refs.form.validate().then(success => {
          if (success) {
            this.save();
          }
        });
    },
    addUser(item) {
      const exist = this.userList.find(el => el.id === item.id);
      if (!exist) {
        this.userList.push(item)
        this.userList.reverse()
        this.filter = '';
      }
    },
    deleteUser(index) {
      this.userList.splice(index, 1)
    },
    shareWithUser(item, index) {
      this.$set(this.userList, index, {...this.userList[index], share: !this.userList[index].share})
    },
    clearForm() {
      this.dealerSelected = '';
      this.arrayModel = [];
      this.arrayGeneration = [];
      this.arrayEngine = [];
      this.dateStart = '';
      this.dateEnd = '';
      this.userList = [];
      this.filter = '';
      this.percentage = 0;
      this.quantity = null;
      this.model = '';
      this.modelId = '';
      this.stage = '';
      this.description = '';
      this.dsg = '';
      this.code = null;
    },
    async generateCode() {
      this.code = generateOnlyRandomString(8)
    },
    showHelp() {
      this.showDivHelp = !this.showDivHelp
    },
    optionCheckGroup() {
      this.types.forEach(type => {
        if (this.model.includes(type.value)) {
          this.typesSelected[type.value] = this.typesSelected[type.value] ? this.typesSelected[type.value] : []
        } else {
          delete this.typesSelected[type.value]
        }
      })
    }
  },
  watch: {
    filter: function (val, oldVal) {
      if (val !== '') {
        this.$refs.btn_collapse.style.display = 'block';
      } else {
        this.$refs.btn_collapse.style.display = 'none';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #c93131; /* Blue */
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.div_collapse {
  position: absolute;
  background: white;
  z-index: 1000;
  display: none;
  width: calc(100% - 30px);
}

.div_ul {
  max-height: 10vw;
  width: 100%;
  overflow-y: scroll;
}

.ul_dealers {
  list-style: none;
  margin-left: 0;
  padding: 0;
  margin-block-end: 0;

  li {
    padding: .2em 1em;
  }

  li:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  li:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
