<template>
  <div>
    <h4 class="text-danger">Create sub-coupon</h4>
    <b-row>
      <b-col class="mb-2 border-bottom">
        <div class="form-group">
          <label>Type(s)</label>
          <b-check-group switches v-model="subCoupon.types" :options="types"/>
        </div>
        <div class="ml-4">
          <div v-for="group of getOptions" class="form-group">
            <label class="mb-0"><strong>{{ group.key }}</strong></label>
            <b-check-group :options="group.values" v-model="subCoupon[group.key]"/>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="subCoupon.types.includes('dsg_family')">
      <b-col class="border-bottom">
        <div><label>DSG Family</label>
          <b-check-group class="grid" v-model="subCoupon.dsg_family" :options="dsgs"/>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="subCoupon.types.includes('vehicle')" class="mt-3">
      <b-col lg="12">
        Specific vehicle
      </b-col>
      <b-col>
        <b-form-select v-model="filter_brand">
          <b-form-select-option value="null">Select brand</b-form-select-option>
          <b-form-select-option v-for="(item, index) in brands" :value="item.make" :key="index">{{
              item.make
            }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select v-model="filter_model">
          <b-form-select-option value="null">Select model</b-form-select-option>
          <b-form-select-option v-for="(item, index) in models" :value="item.model" :key="index">{{
              item.model
            }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select v-model="filter_generation">
          <b-form-select-option value="null">Select generacion</b-form-select-option>
          <b-form-select-option v-for="(item, index) in generations" :value="item.generation" :key="index">
            {{ item.generation }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select v-model="filter_engine">
          <b-form-select-option value="null">Select engine</b-form-select-option>
          <b-form-select-option v-for="(item, index) in engines" :value="item.engine" :key="index">
            {{ item.engine }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select v-model="filter_gearbox" v-show="arrayGearbox.length">
          <b-form-select-option value="null">Select DSG</b-form-select-option>
          <b-form-select-option v-for="(item, index) in arrayGearbox"
                                :value="item.value" :key="index">
            {{ item.text }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <label>Discount percentage</label>
        <b-form-input type="number" v-model.number="subCoupon.percent" min="1" max="100" placeholder="Percent"
                      class="form-control"/>
      </b-col>
      <b-col>
        <label>Quantity</label>
        <b-form-input type="number" v-model.number="subCoupon.limit" placeholder="Quantity" class="form-control"/>
      </b-col>
    </b-row>
    <div class="text-right">
      <b-button @click="addSubCoupon">Add</b-button>
    </div>
    <div class="table-responsive mt-3">
      <table class="table table-bordered">
        <tr>
          <th>Percent / Qt.</th>
          <th>Type (s)</th>
          <th>Type restrictions.</th>
          <th>Options</th>
        </tr>
        <tr v-for="(subCoupon, index) of subCoupons" :key="index">
          <td>{{ subCoupon.percent * 100 }} % / {{ subCoupon.limit }}</td>
          <td>{{ subCoupon.types.join(',') }}</td>
          <td>
            <template v-for="sub of subCoupon.types">
              <p>{{ sub }}: {{ (subCoupon[sub] ? toShow(subCoupon, sub) : 'All') | orderTypeExtended }}</p>
            </template>
          </td>
          <td class="d-flex justify-content-between">
            <b-button-group vertical size="xs">
              <b-button variant="primary" class="border-bottom py-1" :disabled="index === 0"
                        @click="move(index, index - 1)"><i class="fas fa-angle-up"></i></b-button>
              <b-button variant="danger" class="border-top py-1" :disabled="index + 1 === subCoupons.length"
                        @click="move(index, index + 1)"><i class="fas fa-angle-down"></i></b-button>
            </b-button-group>
            <b-button @click="subCoupons.splice(index, 1)" class="btn-danger"><i class="fas fa-trash"></i></b-button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {VehicleSelectorMixin} from "@/utils/vehicle-selector-mixin";
import {get} from "@/services/api";

export default {
  name: "CreateSubCoupon",
  mixins: [
    VehicleSelectorMixin
  ],
  props: {
    coupon_quantity: {
      default: 99999,
    }
  },
  mounted() {
    this.$store.dispatch('orderSoftware/loadBrands')
    this.getDsgs()
  },
  data: function () {
    return {
      defaultAdded: [],
      subCoupons: [],
      subCoupon: {
        limit: null,
        percent: 0,
        types: [],
        dsg_family: [],
        vehicle: {
          brand: null,
          model: null,
          engine: null,
          generation: null
        }
      },
      model: [],
      types: [
        {text: 'ECU', value: 'ECU'},
        {text: 'COMBO', value: 'ECU + DSG'},
        {text: 'DSG', value: 'DSG'},
        {text: 'DSG Family', value: 'dsg_family'},
        {text: 'Vehicle', value: 'vehicle'},
        {text: 'Upgrade', value: 'upgrade'},
      ],
      combos: [
        {text: '1 - 1', value: '1-1'},
        {text: '2 - 2', value: '2-2'},
        {text: '2 - (2+)', value: '2-2.1'},
        {text: '(2+) - (2+)', value: '2.1-2.1'},
        {text: '2 - 3', value: '2-3'},
        {text: '(2+) - 3', value: '2.1-3'},
        {text: '3 - 3', value: '3-3'},
        {text: '3 - 4', value: '3-4'},
        {text: '4 - 3', value: '4-3'},
        {text: '4 - 4', value: '4-4'},
      ],
      ecuOps: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '2+', value: '2.1'},
        {text: '3', value: '3'},
        {text: '4', value: '4'}
      ],
      dsgOps: [
        {text: 'ORIG File', value: 'original_file'},
        {text: 'Drivability', value: 'stock'},
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '2+', value: '2.1'},
        {text: '3', value: '3'},
        {text: '4', value: '4'}
      ],
      dsgs: []
    }
  },
  computed: {
    getOptions() {
      const options = [];
      if (this.subCoupon.types.includes('ECU')) {
        options.push({values: this.ecuOps, key: 'ECU'})
      }
      if (this.subCoupon.types.includes('ECU + DSG')) {
        options.push({values: this.combos, key: 'ECU + DSG'})
      }
      if (this.subCoupon.types.includes('DSG')) {
        options.push({values: this.dsgOps, key: 'DSG'})
      }
      return options
    },
  },
  methods: {
    addSubCoupon() {
      if (this.validate()) {
        this.subCoupon.vehicle = {
          brand: this.filter_brand,
          model: this.filter_model,
          engine: this.filter_engine,
          generation: this.filter_generation
        }
        this.subCoupons.push({
          ...this.subCoupon,
          used: 0,
          percent: this.subCoupon.percent / 100
        });
        this.sort()
        this.subCoupon = {
          limit: null,
          percent: 0,
          types: [],
          vehicle: {
            brand: null,
            model: null,
            engine: null,
            generation: null
          },
        }
        this.clearVehicleFilter()
      }
    },
    optionCheckGroup() {
      this.types.forEach(type => {
        if (this.model.includes(type.value)) {
          this.typesSelected[type.value] = this.typesSelected[type.value] ? this.typesSelected[type.value] : []
        } else {
          delete this.typesSelected[type.value]
        }
      })
    },
    getDsgs() {
      get('vehicles-dsg').then(({data}) => {
        this.dsgs = data.message.map(item => ({value: item.DSG, text: item.DSG}))
      })
    },
    validate() {
      const validateQuantity = (value, coupon_quantity) => {
        return this.coupon_quantity!== null && this.value !== undefined ? this.coupon_quantity >= value : true
      }
      const validator = {
        limit: {
          message: `Quantity must be a number or subcoupon limit must be less or equal than ${this.coupon_quantity}`,
          validate: value => !isNaN(value) && validateQuantity(value, this.coupon_quantity)
        },
        percent: {
          message: 'Percent must be more than 0 and less than 100',
          validate: value => value > 0 && value <= 100
        },
        types: {
          message: 'You must select at least one type',
          validate: () => this.subCoupon.types.length > 0
        }
      }
      const invalidAt = Object.keys(validator).find(key => !validator[key].validate(this.subCoupon[key]))
      if (invalidAt) {
        this.$swal({
          title: 'Error',
          text: validator[invalidAt].message,
          type: 'error',
          confirmButtonColor: "#d14343"
        })
      }
      return !invalidAt
    },
    move(fromIndex, toIndex) {
      this.subCoupons.splice(toIndex, 0, this.subCoupons.splice(fromIndex, 1)[0])
    },
    toShow(subCoupon, key) {
      if (Array.isArray(subCoupon[key])) {
        return subCoupon[key].join(',')
      } else {
        return Object.values(subCoupon[key]).filter(Boolean).join(',')
      }
    },
    addFromDefault(defaultSubCoupon) {
      if (!defaultSubCoupon)
        return;
      if (!this.defaultAdded.includes(defaultSubCoupon.name)) {
        this.defaultAdded.push(defaultSubCoupon.name)
        this.subCoupons.push(...defaultSubCoupon.value)
        this.sort()
      }
    },
    sort() {
      this.subCoupons.sort((a, b) => b.percent - a.percent)
    }
  },
  watch: {
    'coupon_quantity': function (e) {
      this.coupon_quantity = Number(e)
    }
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}
</style>
