import {mapActions, mapMutations, mapState} from "vuex";

export const VehicleSelectorMixin = {
  computed: {
    ...mapState('orderSoftware', ['brands', 'models', 'engines', 'generations', 'filters', 'arrayGearbox']),
    ...mapState('orderSoftware', {
      ecu: state => state.sofwareTunning,
      tcu: state => state.dsgTunning
    }),
    filter_brand: {
      get() {
        return this.$store.state.orderSoftware.filters.brand;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'brand',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'models');
      }
    },
    filter_model: {
      get() {
        return this.$store.state.orderSoftware.filters.model;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'model',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'generations');
      }
    },
    filter_generation: {
      get() {
        return this.$store.state.orderSoftware.filters.generation;
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'generation',
          value
        });
        this.$store.dispatch('orderSoftware/load', 'engines');
      }
    },
    filter_engine: {
      get() {
        return this.$store.state.orderSoftware.filters.engine;
      },
      set(value) {
        if (this.engines.length && this.engines[0].generation) {
          this.$store.state.orderSoftware.filters.generation = this.engines.find(el => el.engine == value).generation;
        }
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'engine',
          value
        });
        this.$store.commit('orderSoftware/clearGearbox')
        this.vehicleOptions(true);
      },
    },
    filter_gearbox: {
      get() {
        return this.$store.state.orderSoftware.filters.gearbox
      },
      set(value) {
        this.$store.commit('orderSoftware/updateFilter', {
          key: 'gearbox',
          value
        });
        this.vehicleOptions(false)
          .then(() => {
            this.showManualSearch = true;
            this.model.dsg_selected = value;
          });
      }
    },
  },
  methods: {
    ...mapActions('orderSoftware', ['vehicleOptions']),
    ...mapMutations('orderSoftware', {
      cleanResult: 'cleanResult',
      clearVehicleFilter: 'clear',
    }),
  }
}
