import Vue from 'vue';
import {get, post} from '@/services/api';
import {couponIndex, couponUpdate} from '../../../services/endpoint.json';
import CreateCoupon from './CreateCoupon.vue';
import DetailCoupon from "@/views/dashboard/coupon/DetailCoupon";
import moment from "moment";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'Coupon',
  mixins: [show_alert_mixin],
  components: {
    CreateCoupon,
    DetailCoupon
  },
  data: () => ({
    tableFields: [
      'users',
      'percent',
      'code',
      'limit',
      'used',
      'sub_coupons',
      'date_end',
      'status',
      'created_at',
      'options'
    ],
    tableData: [],
    fields: [
      {key: 'createdAt', label: 'Date'},
      {key: 'object.vehicle.dsg', label: 'Model'},
      {key: 'object.type', label: 'Type'},
      {key: 'amount', label: 'Credits'},
      'factor',
      // {key: 'euro', class: 'numbers'},
      // 'description',
      'users',
      // {key: 'object.createdAt', label: 'Order date'},
    ],

    arrayCoupon: [],
    arrayMake: [],
    arrayModel: [],
    arrayGeneration: [],
    arrayUsers: [],
  }),
  mounted() {
    this.getData().then();
  },
  methods: {
    async getData() {
      const {data} = await get(couponIndex, null, true);
      this.arrayUsers = data.users;
      this.tableData = data.coupons;
    },
    view(coupon) {
      this.$refs.detail_coupon.open(coupon)
    },
    async deleteCoupon(coupon) {
      if(this.isActive(coupon) || !coupon.status){
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: `Are you sure?`,
          html: ` New status: ${coupon.status?'<span class="font-weight-bold text-danger">Inactive</span>':  '<span class="font-weight-bold text-success">Active</span>' }`,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async res => {
          if (res.isConfirmed) {
            await post(couponUpdate, {id: coupon._id}, true);
            this.getData().then();
          }
        });
      }else{
        this.showAlertNotification(`The coupons has been redeemed or expired, it's not possible to change its status`,'error')
      }
    },
    getPercentage(id) {
      let percentage = "";
      const coupon = this.tableData.find(x => x._id == id)
      percentage = `${(coupon.percent * 100).toFixed(0)}%`
      if (coupon.sub_coupons.length) {
        coupon.sub_coupons.forEach(el => {
          percentage += " - " + (el.percent * 100).toFixed(0) + "%"
        })
      }
      return percentage
    },
    isActive(coupon) {
      const today = moment.utc(moment(), 'DD-MM-YYYY').format("Y-M-DD")
      const status = coupon.status
      const time = coupon.date_end ? moment(today).isSameOrBefore(moment(coupon.date_end)) : true
      const limit = coupon.limit? coupon.limit : 9999
      const quantity = limit > coupon.used
      const find_subcopons = coupon.sub_coupons.length ? coupon.sub_coupons.find(item => {
        const limit = item.limit ? item.limit : 9999
          return limit > item.used
      }): {code: coupon.code, id: coupon.id}
      const sub_coupons = find_subcopons !== undefined
      return status && time && quantity && sub_coupons
    }
  },
});
